<template>
    <page>
        <page-body>
            <data-grid
                :data-source="wishlist"
                :columns="columns"
                :action-column-width="150"
                :action-column-text="translate('crm.User.Table.Action')"
            >

                <div slot-scope="list" slot="ratingTemplate">
                    <div>
                        <i v-for="item in list.row.rating" :key="item" class="icon-star3" style="color:yellow"></i>
                        <i v-for="item in (5-list.row.rating)" :key="item" class="icon-star3" style="color:gray"></i>
                    </div>
                </div>
            </data-grid>
        </page-body>
    </page>

    
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';


const modalId = 'createModal';
const translateKey = 'crm.Wishlist';

export default {
    name: "EventsIndex",
    data() {
        return {
            translateKey,
            modalId,
            permission: 'wishlist',
            columns: [
                {
                    caption: 'Oyun Adı',
                    dataField: 'game_name',
                    show: true
                },
                {
                    caption: 'Say',
                    dataField: 'count',
                    show: true
                },
            ],
        }
    },
    computed: {
        ...mapState('WishlistStore', ['wishlist']),

    },
    methods: {
        ...mapActions('WishlistStore', ['getWishlist']),
        
    },
    created() {
        this.getWishlist();
        console.log(this.getWishlist());
    },
    
}
</script>

<style scoped>

</style>
